import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';

import css from './ListingPage.module.css';

const SectionDocumentationMaybe = props => {
    const { publicData } = props;
    if (!publicData || !publicData.documents) {
        return null;
    }

    const {businessPermit,
    facilityPlan,
    insuranceCertificate,
    operatingPolicies,
    otherDocument} = publicData.documents;

    return (
        <div className={css.sectionFeatures}>
            <h2 className={css.featuresTitle}>
                <FormattedMessage id="ListingPage.documentationTitle" />
            </h2>
            {businessPermit &&
            <div className={css.specsInfo}>
                <a target="_blank" href={businessPermit.location}>
                    <FormattedMessage id="ListingPage.documentationBusinessPermit" />
                </a>
            </div>}
            {facilityPlan &&
            <div className={css.specsInfo}>
                <a target="_blank" href={facilityPlan.location}>
                    <FormattedMessage id="ListingPage.documentationBlueprint" />
                </a>
            </div>}
            {insuranceCertificate &&
            <div className={css.specsInfo}>
                <a target="_blank" href={insuranceCertificate.location}>
                    <FormattedMessage id="ListingPage.documentationInsuranceCertificates" />
                </a>
            </div>}
            {operatingPolicies &&
            <div className={css.specsInfo}>
                <a target="_blank" href={operatingPolicies.location}>
                    <FormattedMessage id="ListingPage.documentationOperatingPolicies" />
                </a>
            </div>}
            {otherDocument &&
            <div className={css.specsInfo}>
                <a target="_blank" href={otherDocument.location}>
                    <FormattedMessage id="ListingPage.documentationOtherDocument" />
                </a>
            </div>}
        </div>
    );
};

export default SectionDocumentationMaybe;
