import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';

import css from './ListingPage.module.css';

const SectionFeaturesMaybe = props => {
    const { publicData } = props;
    if (!publicData || !publicData.specifications) {
        return null;
    }

    const {
        makeOptions,
        typeOfAircraft,
        buildingHeight,
        buildingLength,
        buildingWidth,
        buildingYear,
        hangarPurpose,
        numberOfAvailableSpace,
        thingsToKnow,
        typeOfAccommodation,
    } = publicData.specifications;

    const { maxMTOW } = publicData;

    return (
        <div className={css.sectionFeatures}>
            <h2 className={css.featuresTitle}>
                <FormattedMessage id="ListingPage.featuresTitle" />
            </h2>
            <div className={css.specsItem}>
                <FormattedMessage id="ListingPage.LabelsType" />
                <span className={css.specsInfo}>{typeOfAccommodation}</span>
            </div>
            <div className={css.specsItem}>
                <FormattedMessage id="ListingPage.LabelsPurpose" />
                <span className={css.specsInfo}>{hangarPurpose}</span>
            </div>
            <div className={css.specsItem}>
                <FormattedMessage id="ListingPage.LabelsBuildingYear" />
                <span className={css.specsInfo}>{buildingYear}</span>
            </div>
            <div className={css.specsItem}>
                <FormattedMessage id="ListingPage.LabelsLength" />
                <span className={css.specsInfo}>
                    {buildingLength}m (L) x {buildingWidth}m (W) x{' '}
                    {buildingHeight}m (H)
                </span>
            </div>
            <div className={css.specsItem}>
                <FormattedMessage id="ListingPage.LabelsAvailableSpace" />
                <span className={css.specsInfo}>{numberOfAvailableSpace}m</span>
            </div>
            <div className={css.specsItem}>
                <FormattedMessage id="ListingPage.LabelsMaxMTOW" />
                <span className={css.specsInfo}>{maxMTOW}ton</span>
            </div>
            <div className={css.specsItem}>
                <FormattedMessage id="ListingPage.LabelsMaxSize" />
                <span className={css.specsInfo}>{makeOptions} {typeOfAircraft}</span>
            </div>
            <div className={css.specsItem}>
                <FormattedMessage id="ListingPage.LabelsTTK" />
                <span className={css.specsInfo}>{thingsToKnow}</span>
            </div>
        </div>
    );
};

export default SectionFeaturesMaybe;
