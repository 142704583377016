import React, { useState, useEffect } from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { InlineTextButton } from '../../components';
import css from './ListingPage.module.css';
import { currencyConverter } from '../../util/currency';

const SectionHeading = props => {
    const {
        priceTitle,
        currentListing,
        richTitle,
        category,
        hostLink,
        showContactUser,
        onContactUser,
        currentUser,
    } = props;

    const unitTranslationKey = 'ListingPage.perDay';
    const estimatePriceTip = 'ListingPage.estimatePriceTip';

    const ranges = currentListing.attributes.publicData?.pricing || [];
    const [lowPrice, setLowPrice] = useState(0);
    const [highPrice, setHighPrice] = useState(0);

    useEffect(() => {
        getLowestPrice(ranges);
        getHighestPrice(ranges);
    }, [ranges, currentUser]);

    function getCurrency(currency, price) {
        return currencyConverter({
            from: 'CHF',
            to: currency,
            amount: price,
        })
            .then(result => {
                return result
            })
            .catch(err => console.log(err))
    };

    const displayCurrency = currentUser?.attributes.profile.publicData.displayCurrency || (window !== 'undefined' && localStorage.getItem("displayCurrency"));

    function getLowestPrice(ranges) {
        const sortedRanges = ranges.sort(function (a, b) {
            return a.price - b.price;
        });
        if (displayCurrency) {
            if (displayCurrency.toUpperCase() == 'CHF') {return Number(sortedRanges[0].price)};
            return getCurrency(
                displayCurrency,
                sortedRanges[0].price
            ).then(res => { return setLowPrice(res) });
        }
        else if (sortedRanges[0].listingPrice) { return setLowPrice(sortedRanges[0].listingPrice) }
        else { return setLowPrice(sortedRanges[0].price) };
    };

    function getHighestPrice(ranges) {
        const sortedRanges = ranges.sort(function (a, b) {
            return b.price - a.price;
        });
        if (displayCurrency) {
            if (displayCurrency.toUpperCase() == 'CHF') {return Number(sortedRanges[0].price)};
            getCurrency(
                displayCurrency,
                sortedRanges[0].price
            ).then(res => { return setHighPrice(res) });
        }
        else if (sortedRanges[0].listingPrice) { return setHighPrice(sortedRanges[0].listingPrice) }
        else { return setHighPrice(sortedRanges[0].price) };
    };

    function getCorrectCurrency(ranges) {
        const range = ranges.sort(function (a, b) {
            return a.price - b.price;
        })[0];
        const priceCurrency = currentListing.attributes.price.currency;
        const listingCurrency = range.listingCurrency;
        if (displayCurrency) {
            return displayCurrency.toUpperCase()
        } else if (listingCurrency) {
            return listingCurrency.toUpperCase()
        } else if (priceCurrency) {
            return priceCurrency
        } else {
            return 'CHF'
        }
    }
    const currency = getCorrectCurrency(ranges);
    const isEstimatePrice = displayCurrency !== ranges[0].listingCurrency;
    const lowestPrice = ranges ? Math.round(lowPrice / 100).toFixed(isEstimatePrice ? 0 : 2) : null;
    const highestPrice = ranges ? Math.round(highPrice / 100).toFixed(isEstimatePrice ? 0 : 2) : null;
    const formattedPrice = `${currency} ${lowestPrice} - ${highestPrice}`;

    return (
        <div className={css.sectionHeading}>
            <div className={css.desktopPriceContainer}>
                <div className={css.desktopPriceValue} title={priceTitle}>
                    {formattedPrice}{isEstimatePrice && <span className={css.blueStar}> *</span>}
                </div>
                <div className={css.desktopPerUnit}>
                    <FormattedMessage id={unitTranslationKey} />
                </div>
                {isEstimatePrice &&
                    <div className={css.desktopPerUnit}>
                        <br />
                        <span className={css.blueStar}>* </span><FormattedMessage id={estimatePriceTip} />
                    </div>
                }
            </div>
            <div className={css.heading} style={{ marginTop: '20px' }}>
                <h1 className={css.title}>{richTitle}</h1>
                <div className={css.author}>
                    {category}
                    <FormattedMessage
                        id="ListingPage.hostedBy"
                        values={{ name: hostLink }}
                    />
                    {showContactUser ? (
                        <span className={css.contactWrapper}>
                            <span className={css.separator}>•</span>
                            <InlineTextButton
                                rootClassName={css.contactLink}
                                onClick={onContactUser}
                                enforcePagePreloadFor="SignupPage">
                                <FormattedMessage id="ListingPage.contactUser" />
                            </InlineTextButton>
                        </span>
                    ) : null}
                </div>
            </div>
        </div>
    );
};

export default SectionHeading;
